<template>
  <section>
    <EquipmentHistoryChartDisplay
      ref="chart"
      class="chart-content"
      v-if="show && widgetOptions"
      :widgetOptions="widgetOptions"
      :dataset="dataset"
    />
    <div class="legend" v-if="show && hasContent">
      <img src="/static/common/images/conn-chart.svg" />
    </div>
  </section>
</template>

<script>
import {debounce} from "lodash";
import EventLogService from "@/services/event-log.js";
import EquipmentHistoryChartDisplay from "@/components/equipment-history-chart-display.vue";
// import DATA from "@/assets/eventos_desconexao_repetidos_2.json";
const Colors = () => [
  "#cd7adb",
  "#ffce56",
  "#36a2eb",
  "#4bc0c0",
  "#ff9f40",
  "#cd7adb",
  "#ffce56",
  "#36a2eb",
  "#4bc0c0",
  "#ff9f40",
  "#cd7adb",
  "#ffce56",
  "#36a2eb",
  "#4bc0c0",
  "#ff9f40",
  "#cd7adb",
  "#ffce56",
  "#36a2eb",
  "#4bc0c0",
  "#ff9f40",
  "#cd7adb",
  "#ffce56",
  "#36a2eb",
  "#4bc0c0",
  "#ff9f40",
  "#cd7adb",
  "#ffce56",
  "#36a2eb",
  "#4bc0c0",
  "#ff9f40"
];
export default {
  name: "EquipmentConnectionChart",
  props: {
    connectorIdList: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  components: {
    EquipmentHistoryChartDisplay
  },
  data() {
    return {
      show: false,
      dtini: null,
      dtend: null,
      datasetReady: false,
      xAxisData: [],
      connectorList: []
    };
  },
  computed: {
    contractId() {
      return this.$store.getters["user/contract"]?.id || null;
    },
    widgetOptions() {
      let options = {
        title: {
          show: true,
          textStyle: {
            color: "#bcbcbc"
          },
          text: this.$t("no_data_found"),
          left: "center",
          top: "center"
        }
      };
      let nConectors = Object.keys(this?.entries || {}).length;
      if (!this.hasContent || !nConectors) return options;
      options = {
        series: [],
        animation: false,
        timeWindow: 60,
        refreshInterval: 30,
        trailingValue: false,
        legend: {
          show: true,
          type: "plain",
          textStyle: {fontSize: 14, fontWeight: 300, color: "#000"},
          top: "top",
          left: "center",
          padding: [30, 10, 10, 10]
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {animation: false},
          formatter: this.tooltip,
          extraCssText: "z-index: 1000"
        },
        toolbox: {
          show: false,
          orient: "vertical",
          top: 30,
          feature: {
            restore: {},
            dataZoom: {
              title: {zoom: "area_zoom", back: "back"},
              emphasis: {
                iconStyle: {
                  textFill: "#fff",
                  textBackgroundColor: "rgba(50,50,50,0.7)",
                  textPadding: 5,
                  textPosition: "left"
                }
              }
            }
          }
        },
        xAxis: {
          type: "time",
          show: true,
          boundaryGap: true,
          data: this.xAxisData,
          axisLabel: {
            color: "#000",
            fontSize: 14,
            fontWeight: 300,
            formatter: {
              year: "{yyyy}",
              month: "{MMM}",
              day: "{dd}/{MM}",
              hour: "{HH}:{mm}",
              minute: "{HH}:{mm}",
              second: "{HH}:{mm}:{ss}",
              millisecond: "{hh}:{mm}:{ss} {SSS}",
              none: "{yyyy}-{MM}-{dd} {hh}:{mm}:{ss} {SSS}"
            }
          },
          min: this.xAxisData[0] + 10 * 1000,
          max: this.xAxisData[this.xAxisData.length - 1] - 10 * 1000
        },
        yAxis: {
          type: "value",
          show: false,
          name: "",
          nameLocation: "end",
          nameGap: 0,
          nameRotate: 0,
          minimum: 0,
          maximum: nConectors * 2 - 1,
          interval: 1,
          nameTextStyle: {
            color: "#000",
            backgroundColor: "#fff",
            fontSize: 14,
            fontWeight: 300,
            verticalAlign: "top",
            align: "left",
            borderColor: "rgba(177, 177, 177, 1)",
            borderWidth: 0.5,
            padding: [0, 0, 0, 0]
          },
          axisLabel: {
            show: true,
            fontSize: 16,
            fontWeight: 600,
            textStyle: {
              color: (v) => (parseInt(v) % 2 ? "green" : "red")
            },
            formatter: (v) => (parseInt(v) % 2 ? "Online" : "Offline")
          }
        },
        grid: {
          show: false,
          left: 0,
          right: 45,
          bottom: 10,
          top: 60,
          containLabel: true
        },
        title: {
          show: false,
          text: this.$t("connections"),
          padding: [0, 0, 0, 10],
          backgroundColor: "transparent",
          left: "center",
          textAlign: "center",
          textStyle: {
            color: "#505289",
            fontFamily: "Source Sans Pro",
            fontSize: 14,
            fontStyle: "normal",
            fontWeight: "normal"
          }
        },
        dataZoom: [
          {
            show: false,
            type: "slider"
          },
          {
            show: false,
            type: "inside"
          }
        ],
        height: "auto"
      };
      return options;
    },
    hasContent() {
      return this.datasetReady && this.dataset.length > 0;
    }
  },
  methods: {
    // echart issues: https://github.com/apache/echarts/issues/4263
    tooltip(params) {
      let lst = (params || [])
        .filter(({data}) => data.tooltip !== "")
        .map(({color, data}) => {
          return (data.tooltip || "").replace(/\?color/, color);
        });
      return lst.join("");
    },
    parseResponse(resp) {
      this.datasetReady = false;
      this.entries = null;
      this.dataset = [];
      this.xAxisData = [];
      let result = {};
      if (!resp || !resp.length) {
        this.datasetReady = true;
        return;
      }
      let last = {};
      let entry = {};
      resp
        .filter(
          ({system_sub_event_id}) =>
            system_sub_event_id == 2 || system_sub_event_id == 3
        )
        .forEach((event, ix) => {
          entry[event.connector.id] = entry[event.connector.id] || {};
          if (event.system_sub_event_id == 2) {
            // connected
            entry[event.connector.id].ini = event.date_time_event;
            if (ix == resp.length - 1) {
              entry[event.connector.id].end = this.dtend._d.toISOString();
            }
            last[event.connector.id] = 1;
          } else {
            // disconnected
            if (!entry[event.connector.id].ini) {
              if (
                last[event.connector.id] !== undefined &&
                !last[event.connector.id] &&
                result &&
                result[event.connector.id].length
              ) {
                entry[event.connector.id].ini =
                  result[event.connector.id][
                    result[event.connector.id].length - 1
                  ].ini;
                result[event.connector.id].pop();
              } else {
                entry[event.connector.id].ini = this.dtini._d.toISOString();
              }
            }
            entry[event.connector.id].end = event.date_time_event;
            last[event.connector.id] = 0;
          }
          if (entry[event.connector.id].ini && entry[event.connector.id].end) {
            // console.log(`${entry[event.connector.id].ini} ${entry[event.connector.id].end}`);
            result = result || {};
            result[event.connector.id] = result[event.connector.id] || [];
            result[event.connector.id].push(entry[event.connector.id]);
            delete entry[event.connector.id];
          }
        });
      this.entries = result;
      this.buildDataset();
    },
    resize() {
      if (this.$refs.chart) {
        let chart = this.$refs.chart.getChart();
        if (chart) chart.resize();
      }
    },
    refresh() {
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
    buildDataset() {
      if (!this.entries) return null;
      this.xAxisData = [];
      const tooltip = (ini, end, s, label, connector) => {
        var mI = moment(ini);
        var mE = moment(end);
        var mD = mE.diff(mI);
        if (mD < 1000) return "";
        var fmd =
          mE._d.getTime() - mI._d.getTime() <= 60000
            ? "hh[h]:mm[m]:ss[s]"
            : "hh[h]:mm[m]";
        var fmt = `<i class="fa fa-circle ${
          s ? "text-green" : "text-red"
        }"></i> <span style='color:?color'>${(
          connector?.name || ""
        ).toUpperCase()}</span><br/>\
        ${s ? "Online" : "Offline"} - ${this.$t("duration")}: ${moment
          .duration(mD / 1000, "seconds")
          .format(fmd)} ${label}<br/>\
        ${mI.format("L LT")} - ${mE.format("L LT")}<br/>`;
        // console.log(fmt);
        return fmt;
      };
      let colors = Colors();
      const vpush = (v, dt, vlr, tooltip) => {
        var time = dt.getTime();
        v.push({
          name: `${dt.toISOString()}`,
          value: [time, vlr]
        });
        if (tooltip) {
          v[v.length - 1].tooltip = tooltip;
        }
        this.xAxisData.indexOf(time) == -1 && this.xAxisData.push(time);
      };
      let lst = Object.keys(this?.entries || {}).map((connectorId, ix) => {
        let connector = this.connectorList.find(
          ({id}) => parseInt(id) == parseInt(connectorId)
        );
        let v = [];
        vpush(v, this.dtini._d, ix + 0.1);
        let txt = "";
        let label = ` ${this.$t("since")} ${this.dtini.format("L LT")}`;
        this.entries[connectorId].forEach(({ini, end}) => {
          txt = tooltip(
            new Date(v[v.length - 1].value[0]),
            ini,
            0,
            label,
            connector
          );
          label = "";
          v[v.length - 1].tooltip = txt;
          vpush(v, new Date(new Date(ini).getTime() - 1000), ix + 0.1, txt);
          txt = tooltip(ini, end, 1, label, connector);
          vpush(v, new Date(ini), ix + 1, txt);
          vpush(v, new Date(end), ix + 1, txt);
          vpush(v, new Date(new Date(end).getTime() + 1000), ix + 0.1);
        });
        vpush(v, this.dtend._d, ix + 0.1);
        return {
          name: connector?.name || "",
          zlevel: -1 * (ix * 100),
          data: v,
          type: "line",
          step: "end",
          smooth: false,
          itemStyle: {color: colors.shift(), enabled: true},
          showSymbol: false,
          symbolSize: 1,
          validation: "",
          validationReadValue: true,
          fillOpacity: 1
        };
      });
      this.dataset = lst;
      this.datasetReady = true;
    }
  },
  created() {
    this.fetch = debounce((dtini, dtend, connector_id_list) => {
      const onSuccess = (resp) => {
        if (resp?.length) {
          resp = (resp || []).sort((a, b) =>
            a.date_time_event > b.date_time_event
              ? 1
              : b.date_time_event > a.date_time_event
              ? -1
              : 0
          );
          if (moment(this.dtini) > moment(resp[0].date_time_event)) {
            this.dtini = moment(resp[0].date_time_event);
          }
          if (
            moment(this.dtend) < moment(resp[resp.length - 1].date_time_event)
          ) {
            this.dtend = moment(resp[resp.length - 1].date_time_event);
          }
          this.connectorList = this.$utils.distinct(
            resp.map(({connector}) => connector)
          );
        }
        this.parseResponse(resp);
      };
      this.dtini = dtini;
      this.dtend = dtend;
      let query = {
        contract_id: this.contractId,
        start: moment(dtini)
          .utc()
          .format("YYYY-MM-DDTHH:mm:ss"),
        end: moment(dtend)
          .utc()
          .format("YYYY-MM-DDTHH:mm:ss"),
        system_event_id: 2
      };
      if (connector_id_list?.length) {
        query.connector_ids = connector_id_list.join(",");
      }
      // onSuccess(DATA.filter(({connector}) => connector.id != 2330)); // simulation
      var svr = new EventLogService();
      svr.fetch(query).then((resp) => onSuccess(resp));
    }, 200);
  },
  mounted() {
    let self = this;
    $(window).on("resize", () => {
      setTimeout(() => {
        self.resize();
      }, 200);
    });
  }
};
</script>

<style scoped>
section {
  position: relative;
  margin: 10px 0;
  padding: 10px 0 30px 0;
  z-index: inherit;
  height: 400px;
  border: 1px solid lightgray;
  border-radius: 5px;
}

.chart-content {
  height: 400px;
  min-height: 400px;
}
div.legend {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  text-align: center;
}
div.legend > img {
  display: inline-block;
  margin-top: 5px;
}
</style>
